import React, { Children, cloneElement, memo } from 'react';
import { Link } from 'react-admin';
import classnames from 'classnames';
import get from 'lodash/get';
import { getResourceLinkPath } from 'ra-core';
import { sanitizeRestProps, stopPropagation, useStyles } from '../utils/marterial-ui';


const LinkResourceFieldView = ({
  record, 
  source,
  reference,
  className, 
  basePath,
  children,
  resource,
  idSource,
  link = 'edit',
  translateChoice = false,
  ...props
}) => {
  const classes = useStyles({ classes: null });
  const refRecord = source === '' ? record : get(record, source);
  const path = getResourceLinkPath({ record: refRecord, basePath, resource, source: idSource, reference, link,...props });
  return (
    <Link
        to={path}
        className={className}
        onClick={stopPropagation}
    >
        {cloneElement(Children.only(children), {
            className: classnames(
                children.props.className,
                classes.link // force color override for Typography components
            ),
            record: refRecord,
            resource: reference,
            basePath,
            translateChoice,
            ...sanitizeRestProps(props),
        })}
    </Link>
  );
};

export const LinkResourceField = memo(LinkResourceFieldView);
