import React from 'react';
import {Datagrid, List, TextField, useMutation, useNotify,} from 'react-admin';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {API_TYPES} from "./constants/apiType";
import {useStyles} from "./utils/marterial-ui";
import {oddRowStyle} from "./utils/datagrid";
import SyncIcon from '@material-ui/icons/Sync';


export const SyncButton = ({record, resource}) => {
  const classes = useStyles();
  const notify = useNotify();

  const [syncScope, {loading}] = useMutation(
    {
      type: API_TYPES.SYNC_SCOPE.name,
      resource: resource,
      payload: {
        id: record.id,
      },
    },
    {
      onSuccess: () => {
        notify('Sync scope Finished!');
        window.location.reload();
      },
      onFailure: (error) => notify(`Fail to sync scope: ${error.message}`, 'warning'),
    }
  );

  const icon = loading ? (<CircularProgress/>) : (<SyncIcon/>);

  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      className={classes.button}
      startIcon={icon}
      onClick={syncScope}
    >
      <SyncIcon/>
      Sync Data from db to strapi
    </Button>
  );
};


export const EnvironmentList = props => (
  <List {...props} perPage={50}>
    <Datagrid rowStyle={oddRowStyle}>
      <TextField source="key" label="Key"/>
      <TextField source="name" label="Name"/>
      <TextField source="confDbHost" label="Conf Db Host"/>
      <TextField source="confDbPort" label="Conf Db Port"/>
      <TextField source="confDbName" label="Conf Db Name"/>
      <TextField source="confDbUser" label="Conf Db User"/>
      <SyncButton/>
    </Datagrid>
  </List>
);
