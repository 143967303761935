import React, {useState} from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import {CUSTOM_FILTER_NOT_NULL, CUSTOM_FILTER_NULL, CUSTOM_FILTER_PREFIX} from './constant';
import {Datagrid, DateField, DeleteButton, Edit, EditButton, List, SimpleForm, TextField, TextInput} from 'react-admin';
import {oddRowStyle} from "./utils/datagrid";
import {useStyles} from "./utils/marterial-ui";
import {API_TYPES} from "./constants/apiType";
import {ProgressButton} from "./components/ProgressButton";
import {TooltipIcon} from "./components/TooltipIcon";
import {
  useNotify,
  useMutation,
} from 'react-admin'
import GavelIcon from '@material-ui/icons/Gavel';

export const NotificationList = (props) => {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <div>
      <AppBar position="static">
        <Tabs value={tabIndex} onChange={(item, index) => setTabIndex(index)} aria-label="simple tabs example">
          <Tab label="Active Notification"/>
          <Tab label="Notification History"/>
        </Tabs>
      </AppBar>
      {tabIndex !== 1 && <ActiveNotificationList  {...props}/>}
      {tabIndex === 1 && <NotificationHistoryList  {...props}/>}
    </div>
  )
};


const ResolveButton = ({record, resource}) => {
  const classes = useStyles();
  const notify = useNotify();
  const [resolve, {loading}] = useMutation(
    {
      type: API_TYPES.RESOLVE_NOTIFICATION.name,
      resource,
      payload: {id: record.id},
    },
    {
      onSuccess: () => {
        notify('resolved!');
        window.location.reload();
      },
      onFailure: (error) => notify(`Fail to resolve: ${error.message}`, 'warning'),
    }
  );
  return (
    <ProgressButton
      size="small"
      loading={loading}
      onClick={resolve}
    >
      <TooltipIcon
        title="Resolve"
        size="small"
        className={classes.button}
      >
        <GavelIcon
          color="primary"
        />
      </TooltipIcon>
    </ProgressButton>
  );
};

export const ActiveNotificationList = props => (
  <List {...props} filterDefaultValues={{
    [`${CUSTOM_FILTER_PREFIX}resolvedAt`]: CUSTOM_FILTER_NULL
  }}>
    <Datagrid rowStyle={oddRowStyle}>
      <TextField source="id"/>
      <TextField source="module"/>
      <TextField source="code"/>
      <TextField source="log"/>
      <TextField source="level"/>
      <DateField source="created_at" showTime/>
      <ResolveButton/>
    </Datagrid>
  </List>
);


export const NotificationHistoryList = props => (
  <List {...props} filterDefaultValues={{
    [`${CUSTOM_FILTER_PREFIX}resolvedAt`]: CUSTOM_FILTER_NOT_NULL
  }}>
    <Datagrid rowStyle={oddRowStyle}>
      <TextField source="id"/>
      <TextField source="module"/>
      <TextField source="code"/>
      <TextField source="level"/>
      <DateField source="created_at" showTime/>
      <DeleteButton/>
    </Datagrid>
  </List>
);

export const NotificationEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id"/>
      <TextInput source="name"/>
      <TextInput multiline source="webUrl"/>
    </SimpleForm>
  </Edit>
);
