import React from 'react';
import {Create, Datagrid, DeleteButton, Edit, EditButton, List, SimpleForm, TextField, TextInput} from 'react-admin';
import {LinkResourceField} from './components/LinkResourceField';
import {oddRowStyle} from "./utils/datagrid";

export const LoginCheckerList = props => {
  console.log('LoginCheckerList', props);
  return (
    <List {...props}>
      <Datagrid rowStyle={oddRowStyle}>
        {/* <TextField source="id" /> */}
        <LinkResourceField
          source="systemDomain"
          label="Domain"
          reference="system-domains"
          basePath={props.basePath}
          resource={props.resource}
          idSource="id"
        >
          <TextField source="name"/>
        </LinkResourceField>
        <TextField source="notificationCode"/>
        <TextField source="grantType"/>
        <TextField source="clientId"/>
        <TextField source="username"/>
        <EditButton/>
        <DeleteButton/>
      </Datagrid>
    </List>
  )
};

export const LoginCheckerEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id"/>
      <TextInput source="name"/>
      <TextInput multiline source="webUrl"/>
    </SimpleForm>
  </Edit>
);

export const LoginCheckerCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name"/>
      <TextInput multiline source="webUrl"/>
    </SimpleForm>
  </Create>
);
