import React, {memo} from 'react';
import {JOB_STATE} from "../constants/jobState";
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorOutlineSharpIcon from '@material-ui/icons/ErrorOutlineSharp';
import CheckCircleOutlineSharpIcon from '@material-ui/icons/CheckCircleOutlineSharp';
import { green, red, blue, grey } from '@material-ui/core/colors';
import Link from '@material-ui/core/Link';
import { TooltipIcon } from './TooltipIcon';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

export const JobStateIcon = memo(({state, size, title, showUnStated}) => {
  let icon = null;
  let realTitle  = title;
  if (state === JOB_STATE.Doing) {
    icon = (<CircularProgress size={size} style={{color: blue[500]}}/>);
    realTitle = `${title}-running`;
  } else if (state === JOB_STATE.Success) {
    icon = (<CheckCircleOutlineSharpIcon size={size} style={{color: green[500]}}/>);
    realTitle = `${title}-Success`;
  } else if (state === JOB_STATE.Fail) {
    icon = (<ErrorOutlineSharpIcon size={size} style={{color: red[500]}}/>);
    realTitle = `${title}-fail`;
  } else if (showUnStated === true) {
    icon = (<RemoveCircleOutlineIcon size={size} style={{color: grey[500]}}/>);
    realTitle = `${title}-never run`;
  }
  if (icon == null || title == null) {
    return icon;
  }
  return (
    <TooltipIcon title={realTitle}>
      {icon}
    </TooltipIcon>
  );
});

export const JobStateIconWithLog = memo(({logLink, state, size, title, showUnStated=true}) => {

  if (logLink == null) {
    return (<JobStateIcon title={title} state={state} size={size} showUnStated={showUnStated}/>)  }
  return (
    <Link href={logLink}>
      <JobStateIcon title={title} state={state} size={size} showUnStated={showUnStated}/>
    </Link>
  );
});
