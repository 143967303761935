import { makeStyles } from '@material-ui/core/styles';
import omit from 'lodash/omit';

export const stopPropagation = e => e.stopPropagation();
export const sanitizeRestProps = props =>
  omit(props, [
    'addLabel',
    'allowEmpty',
    'basePath',
    'cellClassName',
    'className',
    'formClassName',
    'headerClassName',
    'label',
    'linkType',
    'link',
    'locale',
    'record',
    'resource',
    'sortable',
    'sortBy',
    'source',
    'textAlign',
    'translateChoice',
  ]);


export const useStyles = makeStyles(
  theme => ({
    link: {
      color: theme.palette.primary.main,
    },
  }),
  {name: 'RaReferenceField'}
);

export const sanitizeInputProps = props => omit(props, [
  "allowEmpty",
  "alwaysOn",
  "basePath",
  "component",
  "defaultValue",
  "formClassName",
  "initialValue",
  "initializeForm",
  "input",
  "isRequired",
  "label",
  "limitChoicesToValue",
  "locale",
  "meta",
  "options",
  "optionText",
  "optionValue",
  "record",
  "resource",
  "source",
  "textAlign",
  "translate",
  "translateChoice",
]);
