import React from 'react';
import {
  Datagrid,
  List,
  TextField,
} from 'react-admin';
import {oddRowStyle} from "../utils/datagrid";

const Items = (props) => {
  if (props.record[props.source] == null) {
    return (<div></div>);
  }
  return (<table>
    {props.record[props.source].map(it => (
      <tr>
        <td>{it.duedate}</td>
        <td><a href={it.issueLink}>{it.issueId}</a></td>
        <td>{it.status}</td>
      </tr>
    ))}
  </table>  );
};

export const DevResource = props => {
  console.log(props);
  return (
    <List {...props}>
      <Datagrid rowStyle={oddRowStyle}>
        <TextField source="name"/>
        {/* <TextField source="detail"/> */}
        <Items source="items" />
      </Datagrid>
    </List>
  );
};
