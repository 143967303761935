import React from 'react';

import {
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  List,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';
import {oddRowStyle} from "../utils/datagrid";

export const SystemDomainConfigurationList = props => {
    return (
        <List {...props}>
            <Datagrid rowStyle={oddRowStyle}>
                <TextField source="systemDomain.name" label="Domain"/>
                <TextField source="cpuThreshold" />
                <TextField source="ramThreshold" />
                <TextField source="diskThreshold" />
                <EditButton/>
                <DeleteButton/>
            </Datagrid>
        </List>
    );
};

export const SystemDomainConfigurationEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <ReferenceInput label="Domain" source="systemDomain" reference="system-domains">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="cpuThreshold" />
            <TextInput source="ramThreshold" />
            <TextInput source="diskThreshold" />
        </SimpleForm>
    </Edit>
);

export const SystemDomainConfigurationCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput label="Domain" source="systemDomain" reference="system-domains">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="cpuThreshold" />
            <TextInput source="ramThreshold" />
            <TextInput source="diskThreshold" />
        </SimpleForm>
    </Create>
);
