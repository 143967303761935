import React, {Children, cloneElement} from 'react';

export default ({extraDataMap, children, ...props}) => {
  const data = props.data;
  let overrideData = data;
  if (data != null && extraDataMap != null) {
    overrideData = {};
    Object.keys(data).forEach(id => {
      const extra = extraDataMap[id];
      if (extra != null) {
        overrideData[id] = {
          ...data[id],
          ...extra
        };
      } else {
        overrideData[id] = data[id];
      }
    });
  }
  return cloneElement(Children.only(children), {
    ...props,
    data: overrideData,
  });
};
