import React from "react";
import {Datagrid, DateField, DeleteButton, EditButton, List, TextField} from 'react-admin';
import {oddRowStyle} from "../utils/datagrid";

export const DomainServerResourceList = props => {
    return (
        <List {...props}>
            <Datagrid rowStyle={oddRowStyle}>
                <DateField source="created_at" showTime label="Time" />
                <TextField source="domainServer.id" label="Domain" />
                <TextField source="domainServer.host" label="Domain server" />
                <TextField source="cpu" />
                <TextField source="ram" />
                <TextField source="totalPartition" />
                <TextField source="largestPartition" />
                <EditButton/>
                <DeleteButton/>
            </Datagrid>
        </List>
    );
};
