const API_TYPES = {
    IMPORT_DEPLOYMENTS: {
        name: '',
        uri: (resource, payload) => `${resource}/${payload.id}/importDeployment`,
        method: 'POST',
    },
    IMPORT_BUILD_INFO: {
        name: '',
        uri: `service-build-infos/importBuild`,
        method: 'POST'
    },
    SYNC_SCOPE: {
      name: '',
      uri: (resource, payload) => `environments/${payload.id}/syncClientAndScope`,
      method: 'POST'
    },
    SYNC_UTILS: {
        name: '',
        uri: (resource, payload) => `${resource}/${payload.id}/syncUtils`,
        method: 'POST'
    },
    SYNC_APP_ENV: {
        name: '',
        uri: (resource, payload) => `${resource}/${payload.id}/syncAppEnv`,
        method: 'POST'
    },
    SYNC_DOMAIN_SCOPE: {
        name: '',
        uri: (resource, payload) => `${resource}/${payload.id}/syncScope`,
        method: 'POST'
    },
    IMPORT_CURRENT_BUILD: {
        name: '',
        uri: (resource, payload) => `${resource}/${payload.id}/impCurBuild`,
        method: 'POST'
    },
    RESET_SERVICE: {
        name: '',
        uri: (resource, payload) => `${resource}/${payload.id}/${payload.nodeId}/reset`,
        method: 'POST'
    },
    STOP_SERVICE: {
        name: '',
        uri: (resource, payload) => `${resource}/${payload.id}/${payload.nodeId}/stop`,
        method: 'POST'
    },
    RESET_ALL_SERVICE: {
        name: '',
        uri: (resource, payload) => `${resource}/${payload.id}/resetAll`,
        method: 'POST'
    },
    APPLY_CONFIG: {
        name: '',
        uri: (resource, payload) => `${resource}/${payload.id}/applyConfig`,
        method: 'POST'
    },
    DEPLOY_SERVICE: {
        name: '',
        uri: (resource, payload) => `${resource}/${payload.id}/deployService`,
        method: 'POST'
    },
    DOMAIN_UPDATE: {
        name: '',
        uri: (resource, payload) => `tradex-service-environments/${payload.id}`,
        method: 'PUT'
    },
    RESOLVE_NOTIFICATION: {
        name: '',
        uri: (resource, payload) => `notifications/${payload.id}/resolve`,
        method: 'POST'
    },
};

Object.keys(API_TYPES).forEach(key => API_TYPES[key].name = key);

export {
    API_TYPES,
};
