// in src/App.js
import React from 'react';
import { fetchUtils, Admin, Resource, ListGuesser, EditGuesser } from 'react-admin';
import { SystemDomainCreate, SystemDomainEdit, SystemDomainList } from './systemDomain';
import { NotificationList, NotificationEdit } from './notification';
import { DevResource } from './resources';
import { LoginCheckerList } from './loginChecker';
import { DomainServiceList } from './domainService';
import { EnvironmentList } from './environments';
import authProvider from './authProvider';
import config from './config';
import strapiDataProvider from './strapiDataProvider';
import { SystemDomainConfigurationCreate, SystemDomainConfigurationList, SystemDomainConfigurationEdit } from "./services/systemDomainConfiguration";
import { DomainServerResourceList } from "./services/domainServerResource";
import { TradexServiceEnvironmentList, TradexServiceEnvironmentEdit } from "./tradexServiceEnv";
import { DeployHistoryList } from './deployServiceHistory';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonalVideoIcon from '@material-ui/icons/PersonalVideo';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};
// const dataProvider = jsonServerProvider('http://jsonplaceholder.typicode.com');
const dataProvider = strapiDataProvider(config.baseUrl, httpClient);

const App = () => (
  <Admin dataProvider={dataProvider} authProvider={authProvider} >
    <Resource name="domain-services" list={DomainServiceList} icon={PersonalVideoIcon} />
    <Resource name="system-domains" list={SystemDomainList} edit={SystemDomainEdit} create={SystemDomainCreate} icon={PersonalVideoIcon} />
    <Resource name="notifications" list={NotificationList} edit={NotificationEdit} options={{ label: 'Notifications' }} icon={PersonalVideoIcon} />
    <Resource name="dev-resources" list={DevResource} options={{ label: 'Api Dev Resources' }} icon={PersonalVideoIcon} />
    <Resource name="login-checkers" list={LoginCheckerList} edit={EditGuesser} icon={PersonalVideoIcon} />
    <Resource name="tradex-services" list={ListGuesser} edit={EditGuesser} icon={SettingsIcon} />
    <Resource name="environments" list={EnvironmentList} icon={SettingsIcon} />
    <Resource name="service-build-infos" list={ListGuesser} show={EditGuesser} icon={PersonalVideoIcon} />
    <Resource name="system-domain-configurations" list={SystemDomainConfigurationList} edit={SystemDomainConfigurationEdit} create={SystemDomainConfigurationCreate} icon={SettingsIcon} />
    <Resource name="domain-server-resources" list={DomainServerResourceList}  icon={PersonalVideoIcon} />
    <Resource name="tradex-service-environments" list={TradexServiceEnvironmentList} edit={TradexServiceEnvironmentEdit} icon={SettingsIcon} />
    <Resource name="deploy-service-history" list={DeployHistoryList} icon={PersonalVideoIcon} />
  </Admin>
);
export default App;
