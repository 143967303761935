import React, {Fragment} from 'react';
import ImportExport from '@material-ui/icons/ImportExport';
import Sync from '@material-ui/icons/Sync';
import {useStyles} from '../utils/marterial-ui';
import {API_TYPES} from '../constants/apiType';

import {
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  List,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useMutation,
  useNotify,
} from 'react-admin';
import {oddRowStyle} from "../utils/datagrid";
import {ProgressButton} from "../components/ProgressButton";
import {JOB_STATE} from "../constants/jobState";
import {TooltipIcon} from "../components/TooltipIcon";
import {JobStateIconWithLog} from "../components/JobStateIcon";

const ImportDomainServiceButton = ({record, resource}) => {
  const classes = useStyles();
  const notify = useNotify();
  const [importDeployment, {loading}] = useMutation(
    {
      type: API_TYPES.IMPORT_DEPLOYMENTS.name,
      resource,
      payload: {id: record.id},
    },
    {
      onSuccess: () => {
        notify('Import Finished!');
      },
      onFailure: (error) => notify(`Fail to import: ${error.message}`, 'warning'),
    }
  );
  return (
    <ProgressButton
      size="small"
      loading={loading}
      onClick={importDeployment}
    >
      <TooltipIcon
        title="Import Server - Service Information"
        size="small"
        className={classes.button}
      >
        <ImportExport
          color="primary"
        />
      </TooltipIcon>
    </ProgressButton>
  );
};

const SyncUtils = ({record, resource}) => {
  const classes = useStyles();
  const notify = useNotify();
  const [syncUtils, {loading}] = useMutation(
    {
      type: API_TYPES.SYNC_UTILS.name,
      resource,
      payload: {id: record.id},
    },
    {
      onSuccess: () => {
        notify('Sync Finished!');
        window.location.reload();
      },
      onFailure: (error) => notify(`Fail to sync: ${error.message}`, 'warning'),
    }
  );
  return (
    <ProgressButton
      size="small"
      loading={loading}
      onClick={syncUtils}
    >
      <TooltipIcon
        title="Sync Utils Files"
        size="small"
        className={classes.button}
      >
        <Sync
          color="secondary"
        />
      </TooltipIcon>
    </ProgressButton>
  );
};

const SyncAppEnv = ({record, resource}) => {
  const classes = useStyles();
  const notify = useNotify();
  const [syncAppEnv, {loading}] = useMutation(
    {
      type: API_TYPES.SYNC_APP_ENV.name,
      resource,
      payload: {id: record.id},
    },
    {
      onSuccess: () => {
        notify('Sync Finished!');
        window.location.reload();
      },
      onFailure: (error) => notify(`Fail to sync: ${error.message}`, 'warning'),
    }
  );
  return (
    <ProgressButton
      size="small"
      loading={loading}
      onClick={syncAppEnv}
    >
      <TooltipIcon
        title="Sync App Env Files"
        size="small"
        className={classes.button}
      >
        <Sync
          color="primary"
        />
      </TooltipIcon>
    </ProgressButton>
  );
};

const SyncScope = ({record, resource}) => {
  const classes = useStyles();
  const notify = useNotify();
  const [syncScope, {loading}] = useMutation(
    {
      type: API_TYPES.SYNC_DOMAIN_SCOPE.name,
      resource,
      payload: {id: record.id},
    },
    {
      onSuccess: () => {
        notify('Sync Scope is running');
      },
      onFailure: (error) => notify(`Fail to sync: ${error.message}`, 'warning'),
    }
  );
  return (
    <ProgressButton
      size="small"
      loading={loading || record.latestSyncScopeState === JOB_STATE.Doing}
      onClick={syncScope}
    >
      <TooltipIcon
        title="Sync Scope"
        size="small"
        className={classes.button}
      >
        <Sync
          color="primary"
        />
      </TooltipIcon>
    </ProgressButton>
  );
};


const ImportCurrentBuild = ({record, resource}) => {
  const classes = useStyles();
  const notify = useNotify();
  const [impCurrBuild, {loading}] = useMutation(
    {
      type: API_TYPES.IMPORT_CURRENT_BUILD.name,
      resource,
      payload: {id: record.id},
    },
    {
      onSuccess: () => {
        notify('import Finished!');
      },
      onFailure: (error) => notify(`Fail to import current build: ${error.message}`, 'warning'),
    }
  );
  return (
    <ProgressButton
      size="small"
      loading={loading}
      onClick={impCurrBuild}
    >
      <TooltipIcon
        title="Import Current Build Number Of Services"
        size="small"
        className={classes.button}
      >
        <ImportExport
          color="secondary"
        />
      </TooltipIcon>
    </ProgressButton>
  );
};


const WrapListButton = (props) => (
  <table>
    <tbody>
    <tr>
      <td>
        <ImportDomainServiceButton {...props} />
      </td>
      <td>
        <ImportCurrentBuild {...props}/>
      </td>
      <td>
        <SyncUtils {...props} />
      </td>
      <td>
        <SyncAppEnv {...props} />
      </td>
      <td>
        <SyncScope {...props} />
      </td>
    </tr>
    </tbody>
  </table>
);

const WrapStatus = ({ record }) => (
  <table>
    <tbody>
    <tr>
      <td>
        <JobStateIconWithLog size={25} state={record.latestSyncScopeState} logLink={record.latestSyncScopeLog} title={"Latest Sync Scope Result"} />
      </td>
    </tr>
    </tbody>
  </table>
);

export const SystemDomainList = props => {
  return (
    <List {...props}>
      <Datagrid rowStyle={oddRowStyle}>
        <TextField source="id"/>
        <TextField source="name"/>
        <TextField source="webUrl"/>
        <TextField source="environment.key" label="Env"/>
        <TextField source="utilsRevision" label="Revision Number"/>
        <WrapListButton/>
        <WrapStatus/>
      </Datagrid>
    </List>
  );
};

export const SystemDomainEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id"/>
      <TextInput source="name"/>
      <TextInput multiline source="webUrl"/>
      <TextInput multiline source="repositoryUrl"/>
      <ReferenceInput label="Env" source="environment.id" reference="environments">
        <SelectInput optionText="name"/>
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const SystemDomainCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name"/>
      <TextInput multiline source="webUrl"/>
      <TextInput multiline source="repositoryUrl"/>
      <ReferenceInput label="Env" source="environment.id" reference="environments">
        <SelectInput optionText="name"/>
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
