import React from 'react';

import {
    Tooltip,
} from '@material-ui/core';
export const TooltipIcon = ({ title, children }) => (
    <Tooltip title={title}>
          {children}
    </Tooltip>
);
