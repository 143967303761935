import config from './config';
import Cookies from './cookies';
import { init, close } from './socketio';

export default {
  // called when the user attempts to log in
  login: ({username, password}) => {
    localStorage.setItem('username', username);
    // accept all username/password combinations
    const request = new Request(`${config.baseUrl}/auth/local`, {
      method: 'POST',
      body: JSON.stringify({
        identifier: username,
        username,
        password
      }),
      headers: new Headers({'Content-Type': 'application/json'})
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        init();
        return response.json();
      })
      .then(response => {
        Cookies.setCookie('token', response.jwt, 1);
        localStorage.setItem('token', response.jwt);
        Cookies.setCookie('role', response.user.role.name, 1);
        response.user.name = response.user.username;
        return response;
      });
  },
  // called when the user clicks on the logout button
  logout: () => {
    Cookies.deleteCookie('token');
    Cookies.deleteCookie('role');
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({status}) => {
    if (status === 401) {
      Cookies.deleteCookie('token');
      Cookies.deleteCookie('role');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    if (Cookies.getCookie('token')) {
      init();
      return Promise.resolve()
    } else {
      close();
      return Promise.reject()
    }
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const role = Cookies.getCookie('role');
    return role ? Promise.resolve(role) : Promise.reject();
  },
};
