import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceInput,
  Filter,
  SelectInput
} from 'react-admin';
import { LinkResourceField } from './components/LinkResourceField';
import {oddRowStyle} from "./utils/datagrid";

const DeployHistoryFilter = (props) => (
  <Filter {...props} >
    <ReferenceInput label="Domain" source="systemDomain"
      reference="system-domains" allowEmpty alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Service" source="tradexServiceEnvironment"
      reference="tradex-services" allowEmpty alwaysOn>
      <SelectInput optionText="key" />
    </ReferenceInput>
  </Filter>
);

export const DeployHistoryList = props => (
  <List {...props} perPage={50} filters={<DeployHistoryFilter />}>
    <Datagrid rowStyle={oddRowStyle}>
      <LinkResourceField
        source="systemDomain"
        label="Domain"
        reference="system-domains"
        basePath={props.basePath}
        resource={props.resource}
        idSource="id"
      >
        <TextField source="name" />
      </LinkResourceField>
      <LinkResourceField
        source="tradexServiceEnvironment"
        label="Service"
        reference="tradex-service-environments"
        basePath={props.basePath}
        resource={props.resource}
        idSource="id"
      >
        <TextField source="key" />
      </LinkResourceField>
      <TextField source="buildNumber" label="Build Number" />
      <TextField source="result" />
      <TextField source="deployTime" />
      <TextField component="pre" source="log" />
    </Datagrid>
  </List>
);
