import {
  CREATE,
  DELETE,
  DELETE_MANY,
  fetchUtils,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
  UPDATE_MANY,
} from 'react-admin';

import {CUSTOM_FILTER_NOT_NULL, CUSTOM_FILTER_NULL, CUSTOM_FILTER_PREFIX} from './constant';
import {API_TYPES} from './constants/apiType';


/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = '';
    const options = {};
    switch (type) {
      case GET_ONE:
        url = `${apiUrl}/${resource}/${params.id}`;
        break;
      case UPDATE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'PUT';
        // Omit created_at/updated_at(RDS) and createdAt/updatedAt(Mongo) in request body
        const {created_at, updated_at, createdAt, updatedAt, ...data} = params.data;
        options.body = JSON.stringify(data);
        break;
      case CREATE:
        url = `${apiUrl}/${resource}`;
        options.method = 'POST';
        options.body = JSON.stringify(params.data);
        break;
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'DELETE';
        break;
      default:
        if (resource === 'dev-resources') {
          return { url: `${apiUrl}/jira-issues/api-dev-resources`, options };
        }
        const externalConfig = API_TYPES[type];
        if (externalConfig != null) {
          url = `${apiUrl}/${externalConfig.uri(resource, params)}`;
          options.method = externalConfig.method;
          break;
        }
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return {url, options};
  };

  /*
      mode = 0: return all
      mode = 1: return only filter
      mode = 2: return only [all, filter]
  */
  const adjustQueryForStrapi = (params, mode = 0) => {

    /*
    params = {
        pagination: { page: {int} , perPage: {int} },
        sort: { field: {string}, order: {string} },
        filter: {Object},
        target: {string}, (REFERENCE ONLY)
        id: {mixed} (REFERENCE ONLY)
    }
    */
    // Handle FILTER
    const f = params.filter;
    let filter = "";
    if (f != null) {
      const keys = Object.keys(f);
      const keyLength = keys.length;
      for (let i = 0; i < keyLength; i++) {
        //react-admin uses q filter in several components and strapi use _q
        const key = keys[i];
        const value = f[key];
        if (key.startsWith("temp:")) continue;
        if (key === "q" && value !== '') {
          filter += "&" + "_q=" + value;
        } else if (key.startsWith(CUSTOM_FILTER_PREFIX)) {
          const realKey = key.substring(CUSTOM_FILTER_PREFIX.length);
          if (value === CUSTOM_FILTER_NULL) {
            filter += "&" + realKey + "_null=true";
          } else if (value === CUSTOM_FILTER_NOT_NULL) {
            filter += "&" + realKey + "_null=false";
          } else {
            filter += "&" + realKey + "=" + value;
          }
        } else {
          if (Array.isArray(value)) {
            value.forEach(v => filter += "&" + key + "=" + v);
          } else {
            filter += "&" + key + "=" + value;
          }
        }
      }
    }
    if (params.id && params.target && params.target.indexOf('_id') !== -1) {
      const target = params.target.substring(0, params.target.length - 3);
      filter += "&" + target + "=" + params.id;
    }

    if (mode === 1) {
      return filter;
    }

    // Handle SORTING
    const s = params.sort;
    const sort = (s == null || s.field === "") ? "_sort=updated_at:DESC" : ("_sort=" + s.field + ":" + s.order);


    // Handle PAGINATION
    let range = "_start=0&_limit=10";
    if (params.pagination) {
      const {page, perPage} = params.pagination;
      const start = (page - 1) * perPage;
      const limit = perPage;//for strapi the _limit params indicate the amount of elements to return in the response
      range = "_start=" + start + "&_limit=" + limit;
    }
    if (mode === 0) {
      return sort + "&" + range + "&" + filter;
    }
    return [sort + "&" + range + "&" + filter, filter];
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type, resource, params) => {
    const json = response;
    switch (type) {
      case CREATE:
        return {data: {...params.data, id: json.id}};
      case DELETE:
        return {data: {id: null}};
      default:
        return {data: json};
    }
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type, resource, params) => {
    console.log('query', type, resource, params);
    try {
      // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
      if (type === UPDATE_MANY) {
        return Promise.all(
          params.ids.map(id => {
            // Omit created_at/updated_at(RDS) and createdAt/updatedAt(Mongo) in request body
            const {created_at, updated_at, createdAt, updatedAt, ...data} = params.data;
            return httpClient(`${apiUrl}/${resource}/${id}`, {
              method: 'PUT',
              body: JSON.stringify(data),
            })
          })
        ).then(responses => ({
          data: responses.map(response => response.json),
        }));
      }
      // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
      else if (type === DELETE_MANY) {
        return Promise.all(
          params.ids.map(id =>
            httpClient(`${apiUrl}/${resource}/${id}`, {
              method: 'DELETE',
            })
          )
        ).then(responses => ({
          data: responses.map(response => response.json),
        }));
      }
      //strapi doesn't handle filters in GET route
      else if (type === GET_MANY) {
        return Promise.all(
          params.ids.map(id =>
            httpClient(`${apiUrl}/${resource}/${id}`, {
              method: 'GET',
            })
          )
        ).then(responses => ({
          data: responses.map(response => response.json),
        }));
      } else if (type === GET_LIST || type === GET_MANY_REFERENCE) {
        if (resource === 'dev-resources') {
          return httpClient(`${apiUrl}/jira-issues/api-dev-resources`, {}).then(res => {
            const data = res.json;
            console.log(data);
            const array = [];
            Object.keys(data.assined).forEach(assignee => {
              console.log(assignee);
              const items = data.assined[assignee];
              let text = "";
              items.forEach(it => text = `${text}<br/>${it.duedate} <a href=${it.issueLink}>${it.issueId}</a> ${it.status}`)
              array.push({
                id: array.length,
                name: assignee,
                detail: text,
                items,
              });
            });
            let noAsstext = "";
            data.noAssignNee.forEach(it => noAsstext = `${noAsstext}<br/>${it.duedate} <a href=${it.issueLink}>${it.issueId}</a> ${it.status}`);
            array.push({
              name: "Not Assigned Yet",
              detail: noAsstext,
            });
            const results = {              
              data: array,
              total: array.length,
              items: data.noAssignNee,
            };
            console.log(results);
            return results;
          });
        }
        const queryParams = adjustQueryForStrapi(params, 2);
        const url = `${apiUrl}/${resource}?${queryParams[0]}`;
        const countUrl = `${apiUrl}/${resource}/count?${queryParams[1]}`;
        console.log('url', url, countUrl);
        return Promise.all([
          httpClient(url, {}),
          httpClient(countUrl, {}),
        ]).then(responses => ({
          data: responses[0].json,
          total: responses[1].json,
        }));
      }

      const {url, options} = convertDataRequestToHTTP(
        type,
        resource,
        params
      );
      return httpClient(url, options).then(response =>
        convertHTTPResponse(response, type, resource, params)
      );
    } catch (e) {
      console.error('fail to make query', type, resource, params, e);
      return new Promise.reject(e);
    }
  };
};
