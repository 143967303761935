import io from 'socket.io-client';
import config from './config';

let socket;
let cbMap = {};
export function init() {
  if (socket != null) {
    return;
  }
  socket = io(config.baseUrl);
  socket.on('connect', () => {
    console.log('connected');
    Object.keys(cbMap).forEach(topic => {
      socket.emit('subscribe', topic);
    });
  });
  socket.on('event', (data) => {
    console.log('event', data);
  });
  socket.on('disconnect', () => {
    console.log('disconnected');
  });
  socket.on('update', (data) => {
    console.log('socketData: ', data);
    const topic = data.r;
    const map = cbMap[topic];
    if (map != null) {
      Object.keys(map).forEach(cbKey => {
        map[cbKey](data.d);
      });
    }
  });
}

export function subscribe(topic, cbKey, callback) {
  if (socket != null) {
    socket.emit('subscribe', topic);
  }
  let map = cbMap[topic];
  if (map == null) {
    map = {cbKey: callback};
    cbMap[topic] = map;
  } else {
    map[cbKey] = callback;
  }
}

export function close() {
  if (socket != null) {
    socket.disconnect(true);
  }
}
