import React from 'react';
import {Datagrid, Edit, EditButton, List, SimpleForm, TextField, TextInput} from 'react-admin';
import {oddRowStyle} from "./utils/datagrid";

export const TradexServiceEnvironmentList = props => (
  <List {...props}>
    <Datagrid rowStyle={oddRowStyle}>
      <TextField source="id"/>
      <TextField source="key"/>
      <TextField source="env"/>
      <TextField source="projectKey"/>
      <TextField source="planKey"/>
      <TextField source="currentBuild.buildNumber" label="buildNumber"/>
      <TextField source="environment.name" label="Environment"/>
      <EditButton/>
    </Datagrid>
  </List>
);

export const TradexServiceEnvironmentEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id"/>
      <TextInput disabled source="key"/>
      <TextInput disabled source="env"/>
      <TextInput source="projectKey"/>
      <TextInput source="planKey"/>
    </SimpleForm>
  </Edit>
);
