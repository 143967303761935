import React, {cloneElement, Fragment, memo, useState} from 'react';
import {Confirm, useMutation, useNotify} from 'react-admin';
import classnames from 'classnames';
import get from 'lodash/get';
import {sanitizeRestProps, useStyles} from '../utils/marterial-ui';
import {API_TYPES} from "../constants/apiType";
import RotateLeft from '@material-ui/icons/RotateLeft';
import Stop from '@material-ui/icons/Stop';

import {TooltipIconButton} from './TooltipIconButton';
import {TooltipIcon} from './TooltipIcon';
import {ProgressButton} from './ProgressButton';


const ResetConfirmButton = ({host, resource}) => {
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  // const classes = useStyles();
  const [reset, {loading}] = useMutation(
    {
      type: API_TYPES.RESET_SERVICE.name,
      resource,
      payload: {
        nodeId: host.nodeId,
        id: host.recordId
      },
    },
    {
      onSuccess: () => {
        notify('Reset Success!');
      },
      onFailure: (error) => notify(`Fail to Reset service: ${error.message}`, 'warning'),
    }
  );
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    setOpen(false);
    reset();
  };
  return (
    <Fragment>
      <ProgressButton 
        onClick={handleClick}
        size='small'
      >
        <TooltipIcon
          size="small"
          title="Reset"
        >
          <RotateLeft color="primary"/>
        </TooltipIcon>
      </ProgressButton>
      <Confirm
        isOpen={open}
        loading={loading}
        title="Select host to reset"
        content="Do you want to do this action?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />

    </Fragment>
  );
};


const StopConfirmButton = ({host, resource}) => {
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const [stop, {loading}] = useMutation(
    {
      type: API_TYPES.STOP_SERVICE.name,
      resource,
      payload: {
        nodeId: host.nodeId,
        id: host.recordId
      },
    },
    {
      onSuccess: () => {
        notify('Stop Success!');
      },
      onFailure: (error) => notify(`Fail to Stop service: ${error.message}`, 'warning'),
    }
  );
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    setOpen(false);
    stop();
  };

  return (
    <Fragment>

      <ProgressButton size='small' onClick={handleClick}>
        <TooltipIcon
          size="small"
          title="Reset"
        >
          <Stop
            color="secondary"/>
        </TooltipIcon>
      </ProgressButton>
      <Confirm
        isOpen={open}
        loading={loading}
        title="Select host to stop"
        content="Do you want to do this action?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />

    </Fragment>
  );
};


const ResetCustomField = ({
                            record,
                            source,
                            reference,
                            className,
                            basePath,
                            children,
                            resource,
                            idSource,
                            data,
                            link = 'edit',
                            translateChoice = false,
                            ...props
                          }) => {
  const classes = useStyles({classes: null});
  const refRecord = source === '' ? record : get(record, source);
  return (
    <table>
      <tbody>
        <tr>
          <td>
            {cloneElement(children, {
              className: classnames(
                children.props.className,
                classes.link // force color override for Typography components
              ),
              record: refRecord,
              resource: reference,
              basePath,
              translateChoice,
              ...sanitizeRestProps(props),
            })}
          </td>
          <td>
            <ResetConfirmButton host={refRecord} resource={resource}/>
          </td>
          <td>
            <StopConfirmButton host={refRecord} resource={resource}/>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
export const ResetCustomFields = memo(ResetCustomField);

const ArrayHostCustomField = ({
                                record,
                                source,
                                reference,
                                className,
                                basePath,
                                children,
                                resource,
                                idSource,
                                data,
                                link = 'edit',
                                translateChoice = false,
                                ...props
                              }) => {
  const classes = useStyles({classes: null});
  const refRecord = source === '' ? record : get(record, source);
  refRecord.domainServers.forEach(obj => {
    obj['recordId'] = `${refRecord.id}`
  });
  return cloneElement(children, {
    className: classnames(
      children.props.className,
      classes.link // force color override for Typography components
    ),
    record: refRecord,
    resource: reference,
    basePath,
    // refRecord,
    // translateChoice,
    ...sanitizeRestProps(props),
  });
};


export const ArrayHostCustomFields = memo(ArrayHostCustomField);
