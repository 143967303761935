import React from 'react';

import {CircularProgress,} from '@material-ui/core';

import Fab from './Fab';

function getSize(size) {
  if (size === "smaller" || size === "small") {
    return [31, -1];
  }
  // if (size === "small") {
  //   return [44, -2];
  // }
  if (size === "medium") {
    return [56, -4];
  }
  if (size === "small") {
    return [44, -2];
  }
  return [68, -6];
}

export const ProgressButton = ({children, loading, size, ...props}) => {
  const [circularSize, posSize] = getSize(size);
  return (
    <div style={{
      display: 'inline',
      alignItems: 'center',
    }}>
      <div style={{
        position: 'relative',
      }}>
        <Fab {...props} size={size}>
          {children}
        </Fab>
        {loading && <CircularProgress size={circularSize} style={{
          position: 'absolute',
          top: posSize,
          left: posSize,
        }}/>}
      </div>
    </div>
  );
};
