import React, { Fragment, useState, useEffect } from 'react';
import {
  Datagrid,
  ArrayField,
  ChipField,
  Confirm,
  Filter,
  List,
  ReferenceInput,
  SelectInput,
  Show,
  SimpleForm,
  SingleFieldList,
  TextField,
  TextInput,
  useNotify,
  useMutation,
  useQuery,
} from 'react-admin';

import { Tooltip } from '@material-ui/core';
import { LinkResourceField } from '../components/LinkResourceField';
import { JobStateIconWithLog } from '../components/JobStateIcon';
import { TooltipIconButton } from '../components/TooltipIconButton';
import { TooltipIcon } from '../components/TooltipIcon';
import { ProgressButton } from '../components/ProgressButton';
import DataGridExtra from '../components/DataGridExtra';
import { subscribe } from './../socketio';
import Button from '@material-ui/core/Button';
import { ArrayHostCustomFields, ResetCustomFields } from '../components/domainHostCustomFIelds';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import TextFieldMU from '@material-ui/core/TextField';

import RotateLeft from '@material-ui/icons/RotateLeft';
import Publish from '@material-ui/icons/Publish';
import { API_TYPES } from '../constants/apiType';
import { useStyles } from '../utils/marterial-ui';

import ImportExport from '@material-ui/icons/ImportExport';
import { oddRowStyle } from '../utils/datagrid';
import { JOB_STATE } from '../constants/jobState';


const ResetAllButton = ({ record, resource }) => {
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const classes = useStyles();
  const [reset, { loading }] = useMutation(
    {
      type: API_TYPES.RESET_ALL_SERVICE.name,
      resource,
      payload: {
        id: record.id,
      },
    },
    {
      onSuccess: () => {
        notify('Reset Success!');
      },
      onFailure: (error) => notify(`Fail to Reset service: ${error.message}`, 'warning'),
    }
  );
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    setOpen(false);
    reset();
  };

  const icon = loading ? <CircularProgress /> : <RotateLeft color="primary" />;
  return (
    <Fragment>
      <ProgressButton
        size="small"
        onClick={handleClick}
      >
        <TooltipIcon
          size="small"
          className={classes.button}
          title="Reset All Instances"
        >
          {icon}
        </TooltipIcon>
      </ProgressButton>
      <Confirm
        isOpen={open}
        loading={loading}
        title="RESET ALL SERVICE"
        content="Do you want to do this action?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

const ApplyConfigButton = ({ record, resource }) => {
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const classes = useStyles();
  const [apply, { loading }] = useMutation(
    {
      type: API_TYPES.APPLY_CONFIG.name,
      resource,
      payload: {
        id: record.id,
      },
    },
    {
      onSuccess: () => {
        notify('Apply Success!');
      },
      onFailure: (error) => notify(`Fail to apply config service: ${error.message}`, 'warning'),
    }
  );
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    setOpen(false);
    apply();
  };

  const icon = loading ? <CircularProgress size={10} /> : <RotateLeft color="secondary" />;
  return (
    <Fragment>
      <ProgressButton
        onClick={handleClick}
        size="small"
      >
        <TooltipIcon
          title="Reset And Apply Latest Environemt"
          size="small"
          className={classes.button}
        >
          {icon}
        </TooltipIcon>
      </ProgressButton>
      <Confirm
        isOpen={open}
        loading={loading}
        title="APPLY CONFIG"
        content="Reset and apply configure. Do you want to do this action?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

const DeployButton = ({ record, resource, recordBuildByNumberId, buildNumber }) => {
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const classes = useStyles();
  const [deploy, { loading }] = useMutation(
    {
      type: API_TYPES.DEPLOY_SERVICE.name,
      resource,
      payload: {
        id: record.id,
        data: {
          newType: true
        }
      },
    },
    {
      onSuccess: () => {
        notify('Deploy is executing.');
      },
      onFailure: (error) => notify(`Fail to deploy service: ${error.message}`, 'warning'),
    }
  );
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    setOpen(false);
    deploy();
  };

  return (
    <Fragment>
      <ProgressButton
        size="small"
        loading={record.latestDeployState === JOB_STATE.Doing || (loading && !buildNumber) || (record.id === recordBuildByNumberId)}
        onClick={handleClick}
      >
        <TooltipIcon
          title="Deloy Latest Build"
          size="small"
          className={classes.button}
        >
          <Publish
            color="secondary"
          />
        </TooltipIcon>
      </ProgressButton>
      <Confirm
        isOpen={open}
        loading={loading}
        title={`DEPLOY: ${record.tradexServiceEnvironment.key}-${record.systemDomain.name}`}
        content={`Do you want to deploy service ${record.tradexServiceEnvironment.key} on ${record.systemDomain.name} at build number ${record.build}?`}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

const DeployByBuildNumberButton = ({ record, resource, setRecordBuilByNumberId, buildNumber, setBuildNumber }) => {
  const [open, setOpen] = useState(false);
  const [openPopupBuildNumber, setOpenPopupBuildNumber] = useState(false);
  const getModalStyle = () => {
    const top = 50 + rand();
    const left = 50 + rand();
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      display: 'flex',
      gap: '20px'
    };
  }
  const rand = () => {
    return Math.round(Math.random() * 20) - 10;
  }
  const useStyles = makeStyles(theme => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      position: 'absolute',
      width: 450,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));
  const notify = useNotify();
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [deploy, { loading }] = useMutation(
    {
      type: 'CREATE', //API_TYPES.DEPLOY_SERVICE.name,
      resource: `${resource}/${record.id}/deployService`,
      payload: {
        id: record.id,
        data: {
          buildNumber: buildNumber * 1,
          newType: true
        }
      },
    },
    {
      onSuccess: () => {
        setRecordBuilByNumberId(0)
        notify('Deploy is executing.');
      },
      onFailure: (error) => notify(`Fail to deploy service: ${error.message}`, 'warning'),
    }
  );
  const handleClick = () => setOpenPopupBuildNumber(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    setRecordBuilByNumberId(record.id);
    setOpenPopupBuildNumber(false);
    setOpen(false);
    setBuildNumber(0);
    deploy();
  };


  return (
    <Fragment>
      <ProgressButton
        size="small"
        loading={record.latestDeployState === JOB_STATE.Doing || loading}
        onClick={handleClick}
      >
        <TooltipIcon
          title="Deloy By Build Number"
          size="small"
          className={classes.button}
        >
          <Publish />
        </TooltipIcon>
      </ProgressButton>
      {
        openPopupBuildNumber ? <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={openPopupBuildNumber}
          onClose={() => setOpenPopupBuildNumber(false)}
        >
          <div style={modalStyle} className={classes.paper}>
            <TextFieldMU
              style={{ flex: '1' }}
              type="number"
              name="buildNumber"
              label="Build Number"
              variant="filled"
              value={buildNumber}
              onChange={event => setBuildNumber(event.target.value)}

            />
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              type="submit"
              onClick={() => buildNumber * 1 > 0 ? setOpen(true) : notify(`Build number is not available`, 'warning')}
            >Deploy</Button>
          </div>
        </Modal> : ''
      }
      <Confirm
        isOpen={open}
        loading={loading}
        title={`DEPLOY: ${record.tradexServiceEnvironment.key}-${record.systemDomain.name}`}
        content={`Do you want to deploy service ${record.tradexServiceEnvironment.key} on ${record.systemDomain.name} at build number ${buildNumber}?`}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

export const DomainServiceFilter = (props) => {
  const setFilters = props.setFilters;
  const [services, setServices] = useState({ loading: true, map: null, list: null });
  const tradexServiceEnvsQuery = useQuery({
    type: 'getList',
    resource: 'tradex-service-environments',
    payload: {
      pagination: {
        page: 1,
        perPage: 400,
      }
    }
  });
  useEffect(
    () => {
      const serviceId = props.filterValues["temp:service"];
      if (props.setFilters != null && services.map != null) {
        props.setFilters({
          ...props.filterValues,
          tradexServiceEnvironment_in: serviceId == null ? undefined : services.map[serviceId],
        });
      }
    },
    [props.filterValues["temp:service"]]
  );
  if (services.loading === true && tradexServiceEnvsQuery.data != null) {
    const serviceData = {};
    const serviceDataArr = [];
    tradexServiceEnvsQuery.data.forEach(sEnv => {
      const service = {
        id: sEnv.tradexService.id,
        key: sEnv.key,
      };
      let data = serviceData[service.id];

      if (data == null) {
        data = [sEnv.id];
        serviceData[service.id] = data;
        serviceDataArr.push(service);
      } else {
        data.push(sEnv.id);
      }
    });
    setServices({
      loading: false,
      map: serviceData,
      list: serviceDataArr,
    });
  }
  return (
    <Filter {...props}>
      <ReferenceInput
        label="Domain"
        source="systemDomain"
        reference="system-domains"
        allowEmpty
        alwaysOn
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      {services.list != null && <SelectInput key={"tmp"}
        alwaysOn
        source="temp:service"
        optionText="key"
        choices={services.list}
      />}
    </Filter>
  );
};

const ImportBuildInfoButton = ({ record, resource }) => {
  const classes = useStyles();
  const notify = useNotify();

  const [importBuildInfo, { loading }] = useMutation(
    {
      type: 'CREATE', //API_TYPES.IMPORT_BUILD_INFO.name
      resource: API_TYPES.IMPORT_BUILD_INFO.uri,
      payload: {
        id: record.id,
        data: {
          isApproved: true,
          serviceName: record.tradexServiceEnvironment.key, //
          env: record.tradexServiceEnvironment.env,
          projectKey: record.tradexServiceEnvironment.projectKey,
          planKey: record.tradexServiceEnvironment.planKey,
        },
      },
    },
    {
      onSuccess: () => {
        notify('Import Finished!');
        window.location.reload();
      },
      onFailure: (error) => notify(`Fail to import: ${error.message}`, 'warning'),
    }
  );

  const icon = loading ? <CircularProgress /> : <ImportExport color="primary" />;
  return (
    <ProgressButton size="small" onClick={importBuildInfo}>
      <TooltipIcon
        size="small"
        className={classes.button}
        title="Import Build Information"
      >
        {icon}
      </TooltipIcon>
    </ProgressButton>
  );
};

const WrapListButton = (props) => (
  <table>
    <tbody>
      <tr>
        <td>
          <ImportBuildInfoButton {...props} />
        </td>
        <td>
          <ResetAllButton {...props} loading={props.record.latestResetState === JOB_STATE.Doing} />
        </td>
        <td>
          <ApplyConfigButton {...props} loading={props.record.latestApplyConfigureState === JOB_STATE.Doing} />
        </td>
        <td>
          <DeployButton {...props} loading={props.record.latestDeployState === JOB_STATE.Doing} />
        </td>
        <td>
          <DeployByBuildNumberButton {...props} loading={props.record.latestDeployState === JOB_STATE.Doing} />
        </td>
      </tr>
    </tbody>
  </table>
);
// state={props.record.latestDeployState}
const WrapStatus = ({ record }) => (
  <table>
    <tbody>
      <tr>
        <td>
          <JobStateIconWithLog size={25} state={record.latestResetState} logLink={record.latestResetLog} title={"Latest Reset"} />
        </td>
        <td>
          <JobStateIconWithLog size={25} state={record.latestApplyConfigureState} logLink={record.latestApplyConfigureLog} title={"Latest Apply Configure"} />
        </td>
        <td>
          <JobStateIconWithLog size={25} state={record.latestDeployState} logLink={record.latestDeployLog} title={"Latest Deploy"} />
        </td>
      </tr>
    </tbody>
  </table>
);

export const DomainServiceList = (props) => {
  const [extraDataMap, setExtraDataMap] = useState({});
  const [recordBuildByNumberId, setRecordBuilByNumberId] = useState(0);
  const [buildNumber, setBuildNumber] = useState(0);
  subscribe('domain-services', 'domain-service-list', (data) => {
    extraDataMap[data.id] = data;
    setExtraDataMap({
      ...extraDataMap,
    });
  });
  return (
    <List {...props} perPage={50} filters={<DomainServiceFilter />}>
      <DataGridExtra extraDataMap={extraDataMap}>
        <Datagrid rowStyle={oddRowStyle}>
          <LinkResourceField
            source="systemDomain"
            label="Domain"
            reference="system-domains"
            basePath={props.basePath}
            resource={props.resource}
            idSource="id"
          >
            <TextField source="name" />
          </LinkResourceField>
          <LinkResourceField
            source="tradexServiceEnvironment"
            label="Service"
            reference="tradex-service-environments"
            basePath={props.basePath}
            resource={props.resource}
            idSource="id"
          >
            <TextField source="key" />
          </LinkResourceField>
          <TextField source="currentDeployment" label="Current build" />
          <TextField source="build" />
          <ArrayHostCustomFields
            source=""
            label="Server"
            reference="domain-services"
            basePath={props.basePath}
            resource={props.resource}
            idSource="id"
          >
            <ArrayField source="domainServers">
              <SingleFieldList>
                <ResetCustomFields
                  source=""
                  label="Server"
                  reference="tradex-services"
                  basePath={props.basePath}
                  resource={props.resource}
                  idSource="id"
                >
                  <ChipField source="nodeId" />
                </ResetCustomFields>
              </SingleFieldList>
            </ArrayField>
          </ArrayHostCustomFields>
          <WrapListButton recordBuildByNumberId={recordBuildByNumberId} setRecordBuilByNumberId={setRecordBuilByNumberId} buildNumber={buildNumber} setBuildNumber={setBuildNumber} />
          <WrapStatus />
        </Datagrid>
      </DataGridExtra>
    </List>
  );
};

export const DomainServiceShow = (props) => (
  <Show {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name" />
      <TextInput multiline source="webUrl" />
    </SimpleForm>
  </Show>
);
